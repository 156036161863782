.wrapper {
    display: flex;
    justify-content: center;
    padding-top: 32px;
}

.section {
    max-width: 90%;
    margin: auto;
}

h5 {
    text-align: center;
    color: #001B84;
}