body {
    position: relative;
}

.logo {
    height: 150px;
}

.loading {
    position: absolute;
    left: 0;
    height: 40%;
    bottom: 0;
    z-index: -1;
    object-fit: cover;
    overflow: hidden;
    animation:animatetop 2s;
}
@keyframes animatetop{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}