* {
  font-family: 'Inter';
}

body {
  width: 100%;
  display: flex;
  margin: 0;
  height: 100vh;
}

#root {
  max-width: 460px;
  margin: auto;
}